<template>
  <v-menu transition="slide-y-transition" bottom>
    <template v-slot:activator="{ on: { click }, attrs }">
      <v-btn @click.prevent="click" icon v-bind="attrs">
        <v-icon :color="iconColor">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="item in optionsList"
        :key="item.value"
        @click="item.action(item)"
      >
        <v-icon
          class="mr-2"
          v-if="item.icon"
          :color="item.disabled ? 'grey' : item.icon == 'mdi-delete' ? 'red' : ''"
          >{{ item.icon }}</v-icon
        ><v-list-item-title
          :class="item.disabled ? 'grey--text' : item.icon == 'mdi-delete' ? 'red--text' : ''"
          >{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Mixins from "@utils/mixins";
export default {
  name: "IntractDropDownMenu",
  mixins: [Mixins.essentials],
  props: {
    optionsList: {
      type: Array,
      default: () => [],
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
};
</script>